import React, { useState, useEffect, useCallback } from 'react'
import '../../styles/splash-screen.css'
import '@material/react-linear-progress/dist/linear-progress.css'
import LinearProgress from '@material/react-linear-progress'
import splash from '../../assets/images/wemeik-logo.png'

// Store
import store from '../../utils/store.js'
import { Provider } from 'react-redux'

function splashImage() {
  return (
    <div className="splash-screen text-center">
      <div>
        <img src={splash} alt="Cargando..." />
        <LinearProgress indeterminate={true} />
      </div>
    </div>
  )
}

const withSplashScreen = (WrappedComponent) => (props) => {
  const [loading, setLoading] = useState(true)

  const isLoading = useCallback(
    () => {
      if (typeof store !== 'object' || Object.keys(store).length === 0 || typeof store.getState !== 'function' || Object.keys(store.getState().company).length === 0) {
        setTimeout(isLoading, 100)
      } else {
        setLoading(false)
      }
    }, [setLoading]
  )

  useEffect(() => {
    isLoading()
  }, [isLoading])

  // while checking user session, show "loading" message
  if (loading) return splashImage()

  // otherwise, show the desired route
  return (
    <Provider store={store}>
      <WrappedComponent {...props} />
    </Provider>
  )
}

export default withSplashScreen
