import React from 'react'
import { convertToCurrency } from '../filters/currency'

const calculateDeliveryCost = function (company) {
  let deliveryCost = company.default_delivery_cost
  if (company.variable_delivery_cost) {
    deliveryCost = <span data-toggle="tooltip" title="El costo será calculado una vez la orden sea aceptada.">(Pendiente)</span>
  } else {
    deliveryCost = convertToCurrency(deliveryCost)
  }

  return deliveryCost
}

const extractErrors = function (error, default_message = '') {
  let error_message = default_message

  if (error.response && error.response.data) {
    if (typeof error.response.data === 'object') {
      error_message = ''
      Object.keys(error.response.data).forEach(function (key) {
        error_message += error.response.data[key][0]
      })
    } else {
      error_message = error.response.data
    }
  }

  return error_message
}

const formatDeliveryTime = function (company) {
  const ddt = company.default_delivery_time
  if (ddt >= 60) {
    const hours = Math.floor(ddt / 60)
    if (ddt % 60 === 0) {
      return `${hours} hr`
    } else {
      const minutes = Math.floor(ddt % 60)
      return `${hours}h:${minutes}m`
    }
  }

  return `${ddt} min`
}

const formatDescription = function (product, ignoreChecked = false) {
  let description = ''
  if (product.additionals) {
    for (let additional of product.additionals) {
      if (additional.checked || ignoreChecked) {
        if (description.length > 0) {
          description += ', '
        }
        description += additional.name
      }
    }
  }

  if (product.observations) {
    for (let observation of product.observations) {
      if (observation.checked || ignoreChecked) {
        if (description.length > 0) {
          description += ', '
        }
        description += observation.name
      }
    }
  }

  if (description.length === 0) {
    description = 'Con todo y sin adiciones.'
  }

  return description
}

const getLocalStorageValue = (key, defaultValue = null) => {
  if (key && window.localStorage) {
    return window.localStorage.getItem(key)
  }

  return defaultValue
}

const setLocalStorageValue = (key, value) => {
  if (key && window.localStorage) {
    window.localStorage.setItem(key, value)
  }
}

const getSessionStorageValue = (key, defaultValue = null) => {
  if (key && window.sessionStorage) {
    return window.sessionStorage.getItem(key)
  }

  return defaultValue
}

const setSessionStorageValue = (key, value) => {
  if (key && window.sessionStorage) {
    window.sessionStorage.setItem(key, value)
  }
}

export { calculateDeliveryCost, extractErrors, formatDeliveryTime, formatDescription, getSessionStorageValue, setSessionStorageValue, getLocalStorageValue, setLocalStorageValue }
